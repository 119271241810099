<template>
  <list-template
    :search-config="searchConfig"
    @onSearch="onSearch"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    :isDownload="true"
    @onAdd="$router.push('./add')"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange" />
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { changeOption } from "@/utils"
import { zsGet } from "@/api"
import { zsApproveStatusClass } from "@/utils/data"
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin],
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      zsGet('/finance/fee-stu/get-map').then(res => {
        const { approve_status, fee_type } = res
        this.searchConfig[1].options = changeOption({ list: approve_status, label: 'approve_status_name', value: 'id' })
        this.searchConfig[2].options = changeOption({ list: fee_type, label: 'fee_type_name', value: 'id' })
      })
    },
    getData() {
      this.getDataApi('/zhaosheng/api/finance/fee-stu/list')
    },
    handleClick(row, handle) {
      const { type } = handle
      if(type === 'edit') {
        this.$router.push(`./edit?id=${row.id}`)
      } else if(type === 'view') {
        this.$router.push(`./detail?id=${row.id}`)
      }
    }
  },
  data() {
    return {
      code: '',
      visible: false,
      searchConfig: [
        { tag: 'input', placeholder: '搜索学生姓名', prop: 'student_name' },
        { tag: 'select', placeholder: '筛选审核状态', prop: 'approve_status', options: [] },
        { tag: 'select', placeholder: '筛选生活费类型', prop: 'fee_type', options: [] },
      ],
      tableConfig: [
        { prop: 'approve_status_text', label: '审核状态', render: ({approve_status_text: status}) => {
          return `<span class="${zsApproveStatusClass(status)}">${status}</span>`
        } },
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'fee_type_text', label: '生活费类型' },
        { prop: 'fee_plan_name', label: '收费方案名称' },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        { label: '操作', width: '130rem', handle: true, fixed: 'right' }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
